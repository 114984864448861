import React from 'react';
import MaskedInput from 'react-maskedinput';

import Button from '../Button/Button';

import styles from './NewAccountForm.module.css';

interface IProps {
	handleSubmit: Function,
}

interface IState {
	cardNumber: string,
	month: string,
	year: string,
	isValid: boolean
}

export default class NewAccountForm extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			cardNumber: '',
			year: '',
			month: '',
			isValid: false,
		};
	}

	handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (this.state.cardNumber === '' || 
			  this.state.year === '' || 
				this.state.month === '') {
					return;
				}
	
		this.props.handleSubmit({ 
			id: undefined, 
			title: `Привязанная карта *${this.state.cardNumber.substr(-4)}`,
			type: 'external'
		});

		this.setState( {
			cardNumber: '',
			year: '',
			month: '',
			isValid: false
		})
	};

	handleInputChange = event => {
		this.setState({
			[event.target.name]: event.target.value,
		} as IState);
		
		this.setState(state => {
			const isValid = 
				/^\d{4} \d{4} \d{4} \d{4}$/.test(state.cardNumber) && 
				(Number(state.month) >= 1 && (Number(state.month) <= 12)) &&
				(Number(state.year) >= new Date().getFullYear() % 100);

			return {
				isValid: isValid
			}
		});
	};

	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				<h2>Привязка банковской карты</h2>
				<div className={styles.cardForm}>
					<div>
						<MaskedInput
							mask="1111 1111 1111 1111"
							name="cardNumber"
							value={this.state.cardNumber}
							onChange={this.handleInputChange}
							placeholder="Номер карты"
							className={styles.input}
						/>
					</div>
					<label className={styles.label}>VALID THRU</label>
					<div className={styles.validThruFieldset}>
						<MaskedInput
							mask="11"
							name="month"
							value={this.state.month}
							onChange={this.handleInputChange}
							placeholder="MM"
							className={`${styles.input} ${styles.inputDate}`}
						/>
						/
						<MaskedInput
							mask="11"
							name="year"
							value={this.state.year}
							onChange={this.handleInputChange}
							placeholder="YY"
							className={`${styles.input} ${styles.inputDate}`}
						/>
					</div>
					
					<Button 
						title={ !this.state.isValid ? 'Проверьте правильность заполнения форм' : undefined } 
						type="submit" 
						disabled={!this.state.isValid}
					>
						Привязать
					</Button>
				</div>
			</form>
		);
	}
}
