import React, { MouseEventHandler } from 'react';
import styles from './Button.module.css';

interface IButton {
  onClick?: MouseEventHandler,
  type?: 'submit' | 'button' | 'reset',
  disabled?: boolean,
  title?: string
}

const Button: React.FC<IButton> = (props) => {
  return (
    <button title={ props.title } disabled={ props.disabled } className={styles.button} type={props.type} onClick={props.onClick}>{props.children}</button>
  );
};

export default Button;
