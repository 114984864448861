import React, { Fragment } from 'react';
import { getCurrencyChar } from '../Utils/getCurrencyChar';

interface IMoney {
  value?: number,
  currency?: string,
}

const getValueSpan = (val: number | undefined) => {
  if (val === undefined) return '';

  const value = val.toString();   

  if (value.indexOf('.') > -1) {
    const array = value.split('.');
    return (
      <>
        <span>{array[0]}</span><span>,{array[1]}</span>
      </>
    );
  } else {
    return <span>{value}</span>;
  }
}

const getCurrencySpan = (val: string | undefined) => {
  const char = getCurrencyChar(val);

  return char !== '' ? <span>{char}</span> : '';
}

const Money: React.FC<IMoney> = (props) => {
  return (
    <span>
      { getValueSpan(props.value) }
      { getCurrencySpan(props.currency) }
    </span>
  );
};

export default Money;
