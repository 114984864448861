import React from 'react';
import { IAccount } from '../../Interfaces/Account';

import BoardItem from '../BoardItem/BoardItem';

import styles from './Board.module.css';

const typeWeight: Record<string, number> = {
  'debit': 10,
  'credit': 15,
  'external': 20,
  'saving': 25,
  'loan': 30
};

const currencyWeigh: Record<string, number> = {
  'RUB': 1,
  'USD': 2,
  'EUR': 3,
  'GBP': 4,
}

const getWeight = (acc: IAccount): number => {
  return typeWeight[acc.type] + (currencyWeigh[acc.currency || ''] || 0);
}

interface IBoard {
  accounts: Array<IAccount>
}

const Board: React.FC<IBoard> = props => (
  <div className={styles.board}>
    {
      props.accounts
        .slice()
        .sort((a, b) => (getWeight(a) - getWeight(b)))
        .map((acc, index) => <BoardItem key={index} {...acc} />)
    }
  </div>
);

export default Board;
