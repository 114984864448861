import React from 'react';
import cn from 'classnames';

import styles from './BoardItem.module.css';
import Money from '../Money/Money';
import { getCurrencyChar } from '../Utils/getCurrencyChar';

interface IBoardItem {
	type?: string,
	amount?: number,
	currency?: string,
	title: string,
	customTitle?: string,
	id?: number 
}

const BoardItem: React.FC<IBoardItem> = (props) => {
	return (
		<div className={styles.item}>
			<div className={cn(styles.logo, styles[`logo_${props.type}`])}>
				{ props.type === 'debit' || props.type === 'credit' ? getCurrencyChar(props.currency) : '' }
			</div>
			<div className={styles.title}>
				{ props.customTitle ?? props.title }
				{ props.type !== 'external' && <div><Money value={props.amount} currency={props.currency} /></div> }
			</div>
		</div>
	);
};

export default BoardItem;
